import { useEffect, useMemo, Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getUsers, cleanUsers } from '../../../redux/reducers/userSlice.js'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import setAuthentication from '../../../utils/setAuthentication.js'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import Tooltip from '@mui/material/Tooltip'
import Button from '@mui/material/Button'

import EditIcon from '@mui/icons-material/Edit'
import CleaningServicesIcon from '@mui/icons-material/CleaningServices'

const UsersList = (props) => {
  const { currentUser, users } = useSelector(state => state.user)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const config = useMemo(()  => {
    return setAuthentication(currentUser)
  }, [currentUser])

  const handleCleaning = () => {
    dispatch(cleanUsers(config))
    dispatch(getUsers(config))
  }

  useEffect(() => {
    if(currentUser?.role === 'Admin') {
      dispatch(getUsers(config))
    } else {
      navigate('/')
    }
  }, [config, dispatch, currentUser, navigate])


  return (
    <>
      <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 2fr'}}>
        {
          users.map(user => (
            <Fragment key={user._id}>
              <Typography>{user.name}</Typography>

              <Box sx={{ display: 'flex', alignItems: 'flex-start'}}>
                <Typography sx={{ mr: 2}}>
                  {user.email}
                </Typography>
                <Link component={RouterLink} to={`/users/${user?._id}`} sx={{ mr: 2}}>
                  show
                </Link>
                <Link component={RouterLink} to={`/users/${user?._id}/edit`}>
                  <Tooltip title='edit' arrow><EditIcon /></Tooltip>
                </Link>
              </Box>
            </Fragment>
          ))
        }
      </Box>
      <Button variant='outlined' startIcon={<CleaningServicesIcon />} onClick={handleCleaning}>
        clean
      </Button>
    </>
  );
};

export default UsersList
