import { useState, useRef } from 'react'
import { useLocation, Link as RouterLink, Navigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import EmailConfirmationMessage from '../../../components/EmailConfirmationMessage/'
import SnackbarMessage from '../../../components/SnackbarMessage/'
import { signinUser } from '../../../redux/reducers/userSlice.js'
import ReCaptcha from '../../../components/ReCaptcha/'

import Box from '@mui/material/Box'
import Avatar from '@mui/material/Avatar'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import LoadingButton from '@mui/lab/LoadingButton'
import Link from '@mui/material/Link'
import PersonIcon from '@mui/icons-material/Person'


const Signin = (props) => {
  const recaptchaRef = useRef()
  const [ email, setEmail ] = useState('')
  const [ password, setPassword ] = useState('')
  const [ rememberMe, setRememberMe ] = useState(true)
  const [ signinInProgress, setSigninInprogress ] = useState(false)

  const location = useLocation()
  const signupSuccess  = location?.state?.signupSuccess
  const notLoggedIn = location?.state?.notLoggedIn
  const message = location?.state?.message

  const { currentUser } = useSelector(state => state.user)
  const dispatch = useDispatch()

  const handleSignin = async (ev) => {
    ev.preventDefault()
    setSigninInprogress(true)
    // add recaptcha check
    const recaptchaToken = await recaptchaRef.current.executeAsync()
    recaptchaRef.current.reset()
    const signinData = { email, password, rememberMe, recaptchaToken }
    dispatch(signinUser(signinData))
    setPassword('')
    setSigninInprogress(false)
  }

  if(currentUser) {
    return <Navigate to='/' />
  }
  return (
    <>
      {/* if user signup, we will show them a message to confirm their email*/}
      { signupSuccess && <EmailConfirmationMessage  />}

      <Box 
        sx={{
          width: 1,
          maxWidth: 360,
          margin: '0 auto',
          marginTop: { md: 6, xs: 4},
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'}}
      >
        <Box sx={{ width: 1, display: 'flex', justifyContent: 'center', pb: 2}}>
          <Avatar sx={{ width: 60, height: 60}}>
            <PersonIcon fontSize='large'/>
          </Avatar>
        </Box>
        <Typography variant='h5' sx={{ mb: 1}}>Login</Typography>
        <Box component='form' onSubmit={handleSignin} sx={{ width: 1}}>
          <TextField
            label='Email'
            type='email'
            size='small'
            margin='normal'
            autoFocus
            fullWidth
            value={email} 
            onChange={(ev) => setEmail(ev.target.value)}
            required
          />
          <TextField
            label='Password'
            type='password'
            size='small'
            margin='normal'
            fullWidth
            value={password} 
            onChange={(ev) => setPassword(ev.target.value)}
            required
          />
          <FormControlLabel
            control={<Checkbox checked={rememberMe} onChange={ev => setRememberMe(ev.target.checked)}/>}
            label='remember me'
          />
          <LoadingButton 
            type='submit'
            loading={signinInProgress} 
            loadingIndicator='Loading...' 
            variant='contained' 
            fullWidth
          >Login</LoadingButton>
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: 1, mt: 1}}>
          <Link component={RouterLink} to='/users/forgotpassword'>Forgot password?</Link>
          <Link component={RouterLink} to='/users/signup'>Signup</Link>
        </Box>

      </Box>
      <ReCaptcha recaptchaRef={recaptchaRef} />
      <SnackbarMessage notLoggedIn={notLoggedIn} message={message} />
    </>
  );
};

export default Signin
