import { useDispatch } from 'react-redux'
import FileBase64 from 'react-filebase64'

import { notify } from '../../redux/reducers/notificationSlice.js'

import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'


const OrganizerForm = ({ state, setState }) => {

  const { organizerState }  = state
  const dispatch = useDispatch()

  return (
    <>
      <Typography sx={{ textDecoration: 'underline'}}>Organizer:</Typography>
      <TextField 
        label='Name'
        size='small'
        autoFocus
        margin='normal'
        value={organizerState.name}
        onChange={(ev) => setState({...state, organizerState: {...organizerState, name: ev.target.value }})}
        onBlur={(ev) => setState({...state, organizerState: {...organizerState, name: ev.target.value.trim()}})}
        required
        fullWidth
      />
      <TextField 
        label='Address'
        size='small'
        margin='normal'
        value={organizerState.address}
        onChange={(ev) => setState({...state, organizerState: {...organizerState, address: ev.target.value }})}
        onBlur={(ev) => setState({...state, organizerState: {...organizerState, address: ev.target.value.trim()}})}
        required
        fullWidth
      />
      <TextField 
        label='Phone'
        type='number'
        size='small'
        margin='normal'
        value={organizerState.phone}
        onChange={(ev) => setState({...state, organizerState: {...organizerState, phone: ev.target.value }})}
        fullWidth
      />
      <TextField 
        label='Url'
        size='small'
        margin='normal'
        value={organizerState.url}
        onChange={(ev) => setState({...state, organizerState: {...organizerState, url: ev.target.value }})}
        fullWidth
      />
      <Button variant='outlined' component='label' size='small' sx={{ my: 2}} endIcon={<CloudUploadIcon />}>
        logo
        <Box sx={{ display: 'none'}}>
          <FileBase64
            accept='image/*'
            type='file'
            multiple={false}
            onDone={({ base64, ...rest}) => {
              if(!rest.type.match(/image\/(jpg|jpeg|png)/) || rest.file.size > 5000000) {
                dispatch(notify({ 
                  message: 'Please use the right file type(image) and size(not greater than 5MB)', 
                  _status: 'error'}))
                window.scroll({ top: 0, behavior: 'smooth'})
                base64='' 
              }
              setState({...state, organizerState: {...organizerState, logoBase64: base64}})
              base64 = ''
            }}
          />
        </Box>
      </Button>
    </>
  );
};

export default OrganizerForm
