import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { getUser } from '../../../redux/reducers/userSlice.js'
import Progress from '../../../components/Progress/'
import Edit from './Edit.js'
import setAuthentication from '../../../utils/setAuthentication.js'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import CheckIcon from '@mui/icons-material/Check'
import ClearIcon from '@mui/icons-material/Clear'


const Profile = (props) => {
  const { id } = useParams()
  const { loading, currentUser, user } = useSelector(state => state.user)
  const dispatch = useDispatch()


  useEffect(() => {
    const config = setAuthentication(currentUser)
    dispatch(getUser({ id, config }))
  }, [id, dispatch, currentUser])


  if(loading) {
    return <Progress />
  }

  return (
    <Box sx={{  width: 1, maxWidth: 360}}>
      <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 1}}>
        <Typography>Name:</Typography>
        <Typography>{ user.name }</Typography>
        <Typography>Email:</Typography>
        <Typography>{ user?.email }</Typography>
        <Typography>eventCount:</Typography>
        <Typography>{ user.eventCount }</Typography>
        <Typography>verified:</Typography>
        <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr'}}>
          <Typography>Email</Typography>
          { user?.verified?.email ? <CheckIcon /> : <ClearIcon /> }
          <Typography>Phone</Typography>
          { user?.verified?.phone ? <CheckIcon /> : <ClearIcon /> }
        </Box>
        <Edit />
      </Box>

    </Box>
  );
};

export default Profile
