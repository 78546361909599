import { useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { notify } from '../../../redux/reducers/notificationSlice.js'
import { postEvent } from '../../../redux/reducers/eventSlice.js'
import setAuthentication from '../../../utils/setAuthentication.js'
import ReCaptcha from '../../../components/ReCaptcha/'

import { Navigate } from 'react-router-dom'

import EventForm from '../../../components/Forms/EventForm.js'
import VenueForm from '../../../components/Forms/VenueForm.js'
import OrganizerForm from '../../../components/Forms/OrganizerForm.js'

import Box from '@mui/material/Box'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import Button from '@mui/material/Button'
import LoadingButton from '@mui/lab/LoadingButton'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'

const steps = ['Event', 'Venue', 'Organizer' ]

const NewEvent = (props) => {
  const [ state, setState ] = useState({
    eventState: { title: '', description: '', category: '', date: [null, null], time: null, image: '' },
    venueState: { name: '', street: '', city: 'Addis Ababa', state: 'Addis Ababa' },
    organizerState: { name: '', address: '', phone: '', logo: '', url: '' }
  })

  const [activeStep, setActiveStep ] = useState(0)
  const [ posted, setPosted ] = useState(false)
  const [ submit, setSubmit ] = useState(false)
  const formRef = useRef(null)
  const recaptchaRef = useRef()
  const { currentUser }  = useSelector(state => state.user)
  const dispatch = useDispatch()


  const handleNext = () => {
    if(!formRef.current.checkValidity()) {
      dispatch(notify({ message: 'Please fill all the required fields', _status: 'error' }))
      window.scroll({ top: 0, behavior: 'smooth' })
      return 
    }
    setActiveStep(prevActiveStep => prevActiveStep + 1)
  }
  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }
  const handlePost = async () =>  {
    setSubmit(true)
    const recaptchaToken = await recaptchaRef.current.executeAsync()
    recaptchaRef.current.reset()

    const config = setAuthentication(currentUser)
    const eventData = {
      _event: state.eventState,
      venue: state.venueState,
      organizer: state.organizerState,
      recaptchaToken
    }

    dispatch(postEvent({eventData, config}))
    setPosted(true)
  }

  const getStepContent = (step) => {
    switch(step){
      case 0: 
        return <EventForm state={state} setState={setState} />
      case 1:
        return <VenueForm state={state} setState={setState} />
      case 2:
        return <OrganizerForm state={state} setState={setState} />
      default: 
        throw new Error('Unknown step')
    }
  }

  return (
    <>
      { posted && <Navigate to={`/users/${currentUser.id}/events`} />}
      { !currentUser && <Navigate to='/users/signin' state={{ notLoggedIn: true, message: 'Please login to post an event' }} />}
      { 
          currentUser?.role !== 'Admin'
          && currentUser?.eventCount >= currentUser?.quota
          && <Navigate 
              to={`/users/${currentUser.id}/events`} 
              state={{ 
                limitReached: true, 
                message: 'Limit reached, delete one of your previous post'
              }} 
            />
      }
      <Container component='form' maxWidth='md' ref={formRef}>
        <Typography align='center' sx={{ mb: 2}}>New Event</Typography>
        <Box>
          <Stepper activeStep={activeStep} alternativeLabel>
            { steps.map(label => (
               <Step key={label}>
                <StepLabel>{ label }</StepLabel>
               </Step>
            ))}
          </Stepper>
        </Box>
        { activeStep === steps.length ? (
            <Box sx={{ 
              display: 'flex', 
              flexDirection: 'column', 
              alignItems: 'center', mt: 2}}
            >
              <Typography sx={{ mb: 2 }}>Please click post to complete</Typography>
              <LoadingButton 
                loading={submit} 
                variant='contained' 
                loadingIndicator='submitting...' 
                onClick={handlePost}>Post</LoadingButton>
            </Box>
          ):( 
            <>
              { getStepContent(activeStep) }
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button disabled={activeStep === 0} onClick={ handleBack }>
                  Back
                </Button>
                <Button onClick={ handleNext } variant='contained'>
                  { activeStep === steps.length - 1 ? 'Done' : 'Next'}
                </Button>
              </Box>
            </>
          )
        }
      </Container>
      <ReCaptcha recaptchaRef={recaptchaRef} />
    </>
  );
};

export default NewEvent
