import { Link as RouterLink, useOutletContext } from 'react-router-dom'
import Link from '@mui/material/Link'
import Tooltip from '@mui/material/Tooltip'
import EditIcon from '@mui/icons-material/Edit'
const Edit = (props) => {

  const  { user } = useOutletContext()
  return (
    <>
      <Link component={RouterLink} to={`/users/${user._id}/edit`}>
        <Tooltip title='edit' arrow>
          <EditIcon />
        </Tooltip>
      </Link>
    </>
  );
};

export default Edit
