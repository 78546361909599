import { useEffect } from 'react'
import { useSearchParams, useLocation, useParams, Link as RouterLink } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import SnackbarMessage from '../../../components/SnackbarMessage/'
import Progress from '../../../components/Progress/'
import { getUserEvents } from '../../../redux/reducers/eventSlice.js'
import Event from '../../../components/Event/'
import { isEventAccessAllowed } from '../../../utils/authorize.js'

import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Link from '@mui/material/Link'

const UserEvents = (props) => {
  const location = useLocation()
  const { id } = useParams()
  const [ searchParams ] = useSearchParams();

  // show the snackbar message when user redirected from 'new event'
  const limitReached = location?.state?.limitReached
  const message = location?.state?.message

  const { loading: eventLoading, userEvents } = useSelector(state => state._event)
  const { loading: userLoading, currentUser } = useSelector(state => state.user)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getUserEvents({id, params: searchParams.toString()})) 
  }, [dispatch, id, searchParams])

  if(eventLoading || userLoading){
    return <Progress />
  }

  return (
    <>
    { currentUser && 
      isEventAccessAllowed(
        currentUser, 'create', 
        { eventCount: currentUser.eventCount, user: { id: null} } ) && 
      <Box sx={{ pb: 2}}>
        <Link component={RouterLink} to='/events/new'>Create an event</Link>
      </Box>
    }
      <Grid container spacing={{ xs: 2, md: 3, lg: 2}}>
        { userEvents.map(_event => <Event key={_event._id} _event={_event} />)}
        <SnackbarMessage limitReached={limitReached} message={message} />
      </Grid>
    </>
  );
};

export default UserEvents
