import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import errorMessage from '../../utils/errorMessage.js'
import { notify } from './notificationSlice.js'
import * as regionAPI from '../../services/region.js'

const initialState = {
  loading: false,
  regions: []
}

export const getRegions = createAsyncThunk(
  'region/getRegions',
  async (params={}, thunkAPI) => {
    try {
      const { data } = await regionAPI.fetchRegions()
      return data
    } catch (error) {
      const message = errorMessage(error)
      thunkAPI.dispatch(notify({ message, _status: 'error'}))
      return thunkAPI.rejectWithValue(message)
    }
  }
)

const regionSlice = createSlice({
  name: 'region',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getRegions.pending, (state) => {
        state.loading = true
      })
      .addCase(getRegions.fulfilled, (state, action) => {
        state.loading = false
        state.regions = action.payload
      })
      .addCase(getRegions.rejected, (state) => {
        state.loading = false
      })
  }
})

export default regionSlice.reducer
