import { useState, useRef } from 'react'

import { Link as RouterLink, Navigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { notify } from '../../../redux/reducers/notificationSlice.js'
import { signupUser } from '../../../services/user.js'
import errorMessage from '../../../utils/errorMessage.js'
import ReCaptcha from '../../../components/ReCaptcha/'

import Box from '@mui/material/Box'
import Avatar from '@mui/material/Avatar'
import TextField from '@mui/material/TextField'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import LoadingButton from '@mui/lab/LoadingButton'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'

import PersonIcon from '@mui/icons-material/Person'

const Signup = (props) => {
  const [ name, setName ] = useState('')
  const [ email, setEmail ] = useState('')
  const [ password, setPassword ] = useState('')
  const [ confirmPassword, setConfirmPassword ] = useState('')
  const [ acceptTerms, setAcceptTerms ] = useState(true)
  const [ signupSuccess, setSignupSuccess ] = useState(false)
  const [ signupInprogress, setSignupInprogress ] = useState(false)
  const recaptchaRef = useRef()

  const { currentUser } = useSelector(state => state.user)
  const dispatch = useDispatch()

  const handleSignup = async (ev) => {
    ev.preventDefault()
    setSignupInprogress(true)
    const recaptchaToken = await recaptchaRef.current.executeAsync()
    recaptchaRef.current.reset()

    if(password !== confirmPassword) {
      setPassword('')
      setConfirmPassword('')
      setSignupInprogress(false)
      return dispatch(notify({ message: 'password and password confirmation does not match', _status: 'error'}))    
    }
    if(!acceptTerms) {
      setSignupInprogress(false)
      return dispatch(notify({ message: 'Please accept terms and conditions', _status: 'info'}))    
    } 
    const userData = {
      name, email, password
    }
    try {
      setSignupInprogress(true)
      const { data } = await signupUser(userData, recaptchaToken)
      dispatch(notify({ message: data.message, _status: 'success' }))
      setSignupSuccess(true)
    } catch (error) {
      const message = errorMessage(error)
      dispatch(notify({ message, _status: 'error'}))
      setSignupInprogress(false)
    } 
  }

  if(currentUser) {
    return <Navigate to='/' />
  }
  if(signupSuccess) {
    return <Navigate to='/users/signin' state={{ signupSuccess: signupSuccess }}/>
  }
  return (
    <Box sx={{ width: 1, maxWidth: 360, margin: '0 auto', marginTop: { md: 6, xs: 4} }}>
      <Box sx={{ display: 'flex', justifyContent: 'center', pb: 2}}>
        <Avatar sx={{ width: 60, height: 60 }}>
          <PersonIcon fontSize='large'/>
        </Avatar>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center'}}>
        <Typography variant='h5' sx={{ mb: 1}}>Signup</Typography>
      </Box>
      <Box component='form' onSubmit={handleSignup} sx={{ width: 1, display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
        <TextField
          label='Name'
          size='small'
          autoFocus
          autoComplete='off'
          margin='normal'
          fullWidth
          value={name}
          onChange={(ev) => setName(ev.target.value)}
          required
        />
        <TextField
          label='Email'
          size='small'
          type='email'
          autoComplete='off'
          margin='normal'
          fullWidth
          value={email}
          onChange={(ev) => setEmail(ev.target.value)}
          required
        />
        <TextField
          label='Password'
          size='small'
          type='password'
          margin='normal'
          fullWidth
          value={password}
          onChange={(ev) => setPassword(ev.target.value)}
          required
        />
        <TextField
          label='Confirm Password'
          size='small'
          type='password'
          margin='normal'
          fullWidth
          value={confirmPassword}
          onChange={(ev) => setConfirmPassword(ev.target.value)}
          required
        />
        <FormControlLabel
          control={<Checkbox checked={acceptTerms} onChange={(ev) => setAcceptTerms(ev.target.checked)}/>}
          label={`Accept terms and conditions`}
        />
        <Box sx={{ width: 1, mt: 1}}>
          <LoadingButton 
            type='submit' 
            variant='contained' 
            loading={signupInprogress} 
            loadingIndicator='In progress...' 
            fullWidth
          >Sign up</LoadingButton>
        </Box>
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'row-reverse', justifyContent: 'space-between', mt: 1}}>
        <Link component={RouterLink} to='/users/signin'>Signin</Link>
        <Box sx={{display: 'flex'}}>
          <Link component={RouterLink} to='/terms'>read</Link>
          <Typography sx={{ pl: 1}}>terms and conditions</Typography>
        </Box>
      </Box>
      <ReCaptcha recaptchaRef={recaptchaRef} />
    </Box>
  );
};

export default Signup
