import { useState, useRef } from 'react'
import { Link as RouterLink, useParams, useNavigate} from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { notify } from '../../../redux/reducers/notificationSlice.js'
import errorMessage from '../../../utils/errorMessage.js'
import ReCaptcha from '../../../components/ReCaptcha/'

import { resetPassword } from '../../../services/user.js'

import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Link from '@mui/material/Link'
import LoadingButton from '@mui/lab/LoadingButton'

const ResetPassword = (props) => {
  const [ password, setPassword ] = useState('')
  const [ passwordConfirmation, setPasswordConfirmation ] = useState('')
  const [ resetInProgress, setResetInProgress ] = useState(false)
  const recaptchaRef = useRef()

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { resetToken } = useParams()

  const handleSubmit = async (ev) => {
    ev.preventDefault()
    setResetInProgress(true)
    const recaptchaToken = await recaptchaRef.current.executeAsync()
    recaptchaRef.current.reset()

    if(password !== passwordConfirmation) {
      setPassword('')
      setPasswordConfirmation('')
      setResetInProgress(false)
      return dispatch(notify({ message: 'password and password confirmation are not same', _status: 'error'}))
    }
    try {
      const { data } = await resetPassword({resetToken, password, recaptchaToken})      
      dispatch(notify({ message: data.message, _status: 'success'}))
      navigate('/users/signin')
    } catch (error) {
      setResetInProgress(false)
      const message = errorMessage(error)
      dispatch(notify({ message, _status: 'error'}))
      setPassword('')
      setPasswordConfirmation('')
    }
  }

  return (
    <Paper 
      sx={{ 
        margin: '0 auto', 
        width: 1, 
        maxWidth: '360px', 
          display: 'flex', 
          flexDirection: 'column', 
          alignItems: 'center', 
          marginTop: { md: 6, xs: 4}, 
          padding: '20px'}}
      >
      <Typography variant='h6' sx={{mb: 1}}>Reset password</Typography>
      <Box 
        component='form' 
        onSubmit={handleSubmit} 
        sx={{ width: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center'}}
      >
        <TextField
          type='password'
          label='Password' 
          autoFocus
          size='small'
          margin='normal'
          value={password}
          onChange={(ev) => setPassword(ev.target.value)}
          required
          fullWidth
        />
        <TextField
          type='password'
          label='Confirm Password' 
          size='small'
          margin='normal'
          value={passwordConfirmation}
          onChange={(ev) => setPasswordConfirmation(ev.target.value)}
          required
          fullWidth
        />
        <LoadingButton 
          type='submit' 
          loading={resetInProgress} 
          loadingIndicator='In progress...'
          variant='contained' 
          fullWidth sx={{my: 1}}
        >Reset</LoadingButton>
      </Box>      
      <Link component={RouterLink} to='/' sx={{ alignSelf: 'flex-end', textDecoration: 'none'}}>Cancel</Link>
      <ReCaptcha recaptchaRef={recaptchaRef} />
    </Paper>
  );
};

export default ResetPassword
