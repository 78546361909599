import { createTheme } from '@mui/material/styles'

const theme = createTheme({
  palette: {
    primary: {
      main: '#171717',
      light: '#3d3d3d',
      dark: '#000000',
      contrastText: '#ffffff'
    },
    secondary: {
      main: '#f4f4f4',
      light: '#c1c1c1',
      dark: '#ffffff',
      contrastText: '#000000'
    }
  },
  typography: {
    htmlFontSize: 16,
    fontSize: 12
  },
  mixins: {
    toolbar: {
      minHeight: 48,
      '@media(min-width: 0px)': {
        '@media(orientation: landscape)': {
          minHeight: 40
        }
      },
      '@media(min-width: 600px)': {
        minHeight: 56
      }
    }
  }
})

export default theme;
