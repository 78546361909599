import { configureStore } from '@reduxjs/toolkit'

import notificationReducer from './reducers/notificationSlice.js'
import userReducer from './reducers/userSlice.js'
import eventReducer from './reducers/eventSlice.js'
import categoryReducer from './reducers/categorySlice.js'
import regionReducer from './reducers/regionSlice.js'

export const store = configureStore({
  reducer: {
    notification: notificationReducer,
    user: userReducer,
    _event: eventReducer,
    category: categoryReducer,
    region: regionReducer
  }
})
