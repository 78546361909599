import axios from 'axios'
const backend_root_url = process.env.REACT_APP_BACKEND_ROOT_URL

export const fetchEvents = async (params) => {
  try {
    return await axios.get(`${backend_root_url}/events?${params}`)     
  } catch (error) {
    throw error
  }
}
export const deleteEvent = async (id, config) => {
  try {
    return await axios.delete(`${backend_root_url}/events/${id}`, config) 
  } catch (error) {
    throw error
  }
}
export const fetchEvent = async (id) => {
  try {
    return await axios.get(`${backend_root_url}/events/${id}`) 
  } catch (error) {
    throw error
  }
}
export const createEvent = async (eventData, config) => {
  try {
    return await axios.post(`${backend_root_url}/events`, eventData, config) 
  } catch (error) {
    throw error
  }
}
export const updateEvent = async (id, eventData, config) => {
  try {
    return await axios.patch(`${backend_root_url}/events/${id}`, eventData, config) 
  } catch (error) {
    throw error
  }
}

export const fetchUserEvents = async (id, params) => {
  try {
    return await axios.get(`${backend_root_url}/users/${id}/events?${params}`) 
  } catch (error) {
    throw error
  }
}
