import { useState, useRef } from 'react'

import { useNavigate, Link as RouterLink } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import ReCaptcha from '../../../components/ReCaptcha/'

import { notify } from '../../../redux/reducers/notificationSlice.js'
import { forgotPasswordRequest } from '../../../services/user.js'
import errorMessage from '../../../utils/errorMessage.js'

import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import LoadingButton from '@mui/lab/LoadingButton'
import Stack from '@mui/material/Stack'
import Paper from '@mui/material/Paper'
import Link from '@mui/material/Link'

const ForgotPassword = (props) => {
  const [ email, setEmail ] = useState('')
  const [ submit, setSubmit ] = useState(false)
  const recaptchaRef = useRef()

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleSubmit = async (ev) => {
    ev.preventDefault()
    setSubmit(true)
    const recaptchaToken = await recaptchaRef.current.executeAsync()
    recaptchaRef.current.reset()

    try {
      const { data } = await forgotPasswordRequest(email, recaptchaToken)
      dispatch(notify({ message: data.message, _status: 'success'}))
      navigate(-1)
    } catch (error) {
      const message = errorMessage(error)
      dispatch(notify({ message, _status: 'error'}))
      setSubmit(false)
    }
  }
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: { md: 6, xs: 4} }}>
      <Paper sx={{ width: 1, maxWidth: 360, padding: 3}}>
        <Typography variant='h5' sx={{ textAlign: 'center', mb: 2}}>Forgot password</Typography> 
        <Typography sx={{ textAlign: 'justify', mb: 2}}>
          Enter your signup email address and click Submit, we will send you password reset link to this email.
        </Typography>
        <Stack  component='form' onSubmit={handleSubmit} spacing={1} sx={{ mb: 2}}>
          <TextField
            label='Email'
            type='email'
            autoFocus
            autoComplete='off'
            margin='normal'
            size='small'
            value={email}
            onChange={(ev) => setEmail(ev.target.value)}
            required
            fullWidth
          />
          <LoadingButton 
            type='submit' 
            loading={submit} 
            loadingIndicator='submitting...' 
            variant='contained' 
            fullWidth
          >
            Submit
          </LoadingButton>
        </Stack>
        <Stack justifyContent='center'>
          <Typography sx={{ textAlign: 'justify', mb: 1}}>
            Reset request will expire after some time, so after sending your request check your email.
          </Typography>
          <Typography>
            You can not submit another request before the expiration of the previous request.
          </Typography>
        </Stack>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', my: 1}}>
          <Link component={RouterLink} to='/users/signin'>Sign in</Link>
        </Box>
      </Paper>
      <ReCaptcha recaptchaRef={recaptchaRef} />
    </Box>
  );
};

export default ForgotPassword
