import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { getEvents } from '../../redux/reducers/eventSlice.js'

import Progress from '../../components/Progress/'
import Event from '../../components/Event/'
import Filter from '../../components/Filter/'

import Grid from '@mui/material/Grid'
import Pagination from '@mui/material/Pagination'
import Fab from '@mui/material/Fab'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'

const Events = (props) => {
  const [ top, setTop ] = useState(true)
  const [ searchParams, setSearchParams ] = useSearchParams()
  const [ page, setPage ] = useState(parseInt(searchParams.get('page')) || 1)

  const { loading, events, pages } = useSelector(state => state._event)
  const dispatch = useDispatch()

  const handlePageChange = (ev, newValue) => {
    setPage(newValue)
    searchParams.set('page', newValue)
    setSearchParams(searchParams.toString())
  }

  const topEvent = () => {
    if(window.scrollY > 200) {
      setTop(false)
    } else {
      setTop(true)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', topEvent)
    return () => {
      window.removeEventListener('scroll', topEvent)
    }
  }, [])

  useEffect(() => {
    dispatch(getEvents(searchParams.toString()))
    window.scroll({ top: 0, behavior: 'smooth'})
  }, [dispatch, searchParams])

  if(loading) {
    return <Progress />
  }
  return (
    <>
      <Filter />
      <Pagination  count={pages} page={page} onChange={handlePageChange} sx={{ mb: 2}} />
      <Grid container spacing={{ xs: 2, md: 3, lg: 2 }}>
        {
          events.map(_event => (
            <Event key={_event._id} _event={_event} />
          ))
        }
      </Grid>
      { !top &&
        <Fab size='small'
          sx={{
            position: 'fixed',
            bottom: 20,
            right: 10
          }}
          onClick={() => window.scroll({ top: 0, behavior: 'smooth' })}
        >
          <KeyboardArrowUpIcon />
        </Fab>
      }
    </>
  );
};

export default Events
