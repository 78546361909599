import { useSelector } from 'react-redux'
import { Outlet } from 'react-router-dom'

const User = (props) => {

  const { user } = useSelector(state => state.user)
  return (
    <>
      <Outlet context={{user}} />
    </>
  );
};

export default User
