import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'

const Terms = (props) => {

  return (
    <Container maxWidth='xs'>
      <Typography variant='h6' mt={3} mb={1} sx={{ textAlign: 'center'}}>Terms and conditions</Typography>
      <Box sx={{ textAlign: 'justify' }}>
        <Typography>
These Terms and conditions govern your('the User') use of the website located at the domain name 'boo.et', By accessing and using the website('boo.et'), the User agree to be bound by the Terms and conditions set out in this notice. In addition, your continued use of this website following the posting of any updates or changes to our terms and conditions shall mean that you are in agreement.
        </Typography>
As part of the registration, we will ask you your login credential to provide certain registration information which must be accurate and updated, you shall not misrepresent your identity, affiliation or status, or impersonate anyone else,
Your registration is for a single user only, and you are not allowed to share your registration login credential. you are responsible for maintaining the confidentiality of your password and use complex and unique password for this website.
        <Typography>

        </Typography>

        <Typography>
When you visit the website we collect your login data to effectively provide the services of the website, and we use free services of other websites or service providers such as (https://www.google.com, https://www.mongodb.com, https://www.sendgrid.com, https://www.cloudinary.com, https://www.heroku.com/home, https://www.salesforce.com, https://www.netlify.com), by continuing using this website('boo.et') you also agreed to their corresponding  privacy policy and,  terms and conditions.
        </Typography>

        <Typography>
The contents of the pages of this website is posted by the 'users' and it is for your information and use only, it is subjected to changes without notice, the website owner does not provide any warranty or guarantee as to the accuracy, completeness or suitability of the information and material found on this website for any particular purpose, You acknowledge that such information and material may contain inaccuracies or errors and we expressely exclude liability for any such inaccuracies or errors to the fullest extent permitted by law.
        </Typography>

        <Typography>
Your use of any information or materials on this website is entirely at your own risk for which we shall not be liable, It shall be your own responsibility to ensure that any information available through this website meets your specific requirements.
        </Typography>

        <Typography>
All content uploaded, posted or otherwise submitted to the website is the sole responsibility of the account holder from which such content originates and you acknowledge and agree that you , and not the owner of this website are responsible for all content that you upload, post, or otherwise submit to the website, and you represent to us that you have all the necessary legal rights to upload, post or submit such content and it will not violate any law or the right of any person.
        </Typography>
        <Typography>
You shall not upload to, or otherwise post on to the service any libelous, defamatory, obscene, pornographic, abusive, or otherwise illegal materials.
        </Typography>
        <Typography>
Access to this website is permitted on a temporary basis and we reserve the right to withdraw or amend the service without any notice, we will not be liable if for any reason this website is unavailable at anytime or for any period.
        </Typography>

        <Typography>
You must be 18 years and older to use the services provided by the website.
        </Typography>
      </Box>
    </Container>
  );
};

export default Terms
