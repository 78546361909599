import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import errorMessage from '../../utils/errorMessage.js'
import { notify } from './notificationSlice.js'
import * as categoryAPI from '../../services/category.js'

const initialState = {
  loading: false,
  categories: []
}

export const getCategories = createAsyncThunk(
  'category/getCategories',
  async (params={}, thunkAPI) => {
    try {
      const { data } = await categoryAPI.fetchCategories()
      return data
    } catch (error) {
      const message = errorMessage(error)
      thunkAPI.dispatch(notify({ message, _status: 'error'}))
      return thunkAPI.rejectWithValue(message)
    }
  }
)

const categorySlice = createSlice({
  name: 'category',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCategories.pending, (state) => {
        state.loading = true
      })
      .addCase(getCategories.fulfilled, (state, action) => {
        state.loading = false
        state.categories = action.payload
      })
      .addCase(getCategories.rejected, (state) => {
        state.loading = false
      })
  }
})

export default categorySlice.reducer
