import { Outlet } from 'react-router-dom'

const Pages = (props) => {

  return (
    <>
      <Outlet />      
    </>
  );
};

export default Pages
