import { useState } from 'react'
import { useOutletContext, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { updateUser } from '../../../redux/reducers/userSlice.js'
import setAuthentication from '../../../utils/setAuthentication.js'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import LoadingButton from '@mui/lab/LoadingButton'

const EditProfile = (props) => {
  const { user } = useOutletContext()
  const [ name, setName ] = useState(user.name)
  const [ phone, setPhone ] = useState(user.phone || '')
  const [ updateInProgress, setUpdateInProgress ] = useState(false)

  const { currentUser } = useSelector(state => state.user)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const config = setAuthentication(currentUser)
  const handleSubmit = (ev) => {
    ev.preventDefault()
    setUpdateInProgress(true)
    const updateInfo = { name, phone }
    dispatch(updateUser({id: user._id, updateInfo, config}))
    setUpdateInProgress(false)
    navigate(`/users/${user._id}`)
  }
  return (
    <Box sx={{ width: 1, maxWidth: 360, display: 'flex', flexDirection: 'column'}}>
      <Typography variant='h6'>Edit Profile:</Typography>
      <Box component='form' onSubmit={handleSubmit} sx={{ display: 'flex', flexDirection: 'column'}}>
        <TextField
          label='Name'
          autoFocus
          size='small'
          margin='normal'
          autoComplete='off'
          value={name}
          onChange={(ev) => setName(ev.target.value)}
        />
        <TextField
          type='number'
          label='Phone'
          size='small'
          margin='normal'
          autoComplete='off'
          value={phone}
          onChange={(ev) => setPhone(ev.target.value)}
        />
        <LoadingButton type='submit' variant='contained' loading={updateInProgress} loadingIndicator='updating...' sx={{ my: 1}}>
          Update 
        </LoadingButton>
      </Box>
      <Button onClick={() => navigate(-1)}>Cancel</Button>
    </Box>
  );
};

export default EditProfile
