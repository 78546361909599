import axios from 'axios'
const backend_root_url = process.env.REACT_APP_BACKEND_ROOT_URL

export const signupUser = async (userData, recaptchaToken) => {
  try {
    return await axios.post(`${backend_root_url}/users/signup`, {userData, recaptchaToken})
  } catch (error) {
    throw error
  }
}
export const signinUser = async (signinData) => {
  try {
    return await axios.post(`${backend_root_url}/users/signin`, signinData) 
  } catch (error) {
    throw error
  }
}
export const verifyUserJWToken = async (token) => {
  try {
    return await axios.post(`${backend_root_url}/users/verifyuserjwtoken`, token) 
  } catch (error) {
    throw error
  }
}
export const verifyEmail = async (token) => {
  try {
    return await axios.get(`${backend_root_url}/users/verifyemail/${token}`) 
  } catch (error) {
    throw error
  }
}
export const forgotPasswordRequest = async (email, recaptchaToken) => {
  try {
    return await axios.post(`${backend_root_url}/users/forgotpassword`, { email, recaptchaToken }) 
  } catch (error) {
    throw error
  }
}
export const resetPassword = async ({ resetToken, password, recaptchaToken }) => {
  try {
    return await axios.post(`${backend_root_url}/users/resetpassword`, { resetToken, password, recaptchaToken }) 
  } catch (error) {
    throw error
  }
}
export const fetchUser = async (id, config) => {
  try {
    return await axios.get(`${backend_root_url}/users/${id}`, config) 
  } catch (error) {
    throw error
  }
}
export const updateUser = async (id, updateInfo, config) => {
  try {
    return await axios.patch(`${backend_root_url}/users/${id}/edit`, updateInfo, config) 
  } catch (error) {
    throw error
  }
}
export const fetchUsers = async (config) => {
  try {
    return await axios.get(`${backend_root_url}/users`, config) 
  } catch (error) {
    throw error
  }
}
export const cleanUsers = async (config) => {
  try {
    return await axios.get(`${backend_root_url}/users/clean`, config) 
  } catch (error) {
    throw error
  }
}
