import { subject } from '@casl/ability'
import defineAbilityFor from './abilityBuilder.js'

export const isEventAccessAllowed = (user, action, ev={ user: { id: null}, eventCount: 0}) => {
  const ability = defineAbilityFor(user)
  const _event = subject('Event', { userId: ev.user.id, eventCount: ev.eventCount})
  return ability.can(action, _event)
}
export const isUserAccessAllowed = (user, action, usr={ id: null}) => {
  const ability = defineAbilityFor(user)
  const u =  subject('User', { id: usr.id })
  return ability.can(action, u)
}
