import { Link as RouterLink, useNavigate } from 'react-router-dom'
import Link from '@mui/material/Link'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'

const NotFound = (props) => {
  const navigate = useNavigate()

  return (
    <Paper
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: 1,
        padding: '20px',
        maxWidth: '360px',
        margin: '0 auto',
        marginTop: 8
      }}
    >
      <Typography variant='h5'>404</Typography> 
      <Typography>Noting found here!</Typography> 
      <Box
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: 1, my: 2}}
      >
        <Button variant='outlined' onClick={() => navigate(-1)}>Back</Button> 
        <Link component={RouterLink} to='/' sx={{ml: 2, textDecoration: 'none'}}>home</Link> 
      </Box>
    </Paper>
  );
};

export default NotFound
