import { useSelector, useDispatch } from 'react-redux'
import FileBase64 from 'react-filebase64'
import { notify } from '../../redux/reducers/notificationSlice.js'
import Progress from '../../components/Progress/'

import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'

import LocalizationProvider from '@mui/lab/LocalizationProvider'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import  DateRangePicker  from '@mui/lab/DateRangePicker'
import  TimePicker  from '@mui/lab/TimePicker'
import afLocale from 'date-fns/locale/af'

const EventForm = ({ state, setState }) => {
  const { eventState } = state
  const { categories } = useSelector(state => state.category)

  const dispatch = useDispatch()

  if(!eventState) {
    return <Progress />
  }
  return (
    <>
      <Typography sx={{ textDecoration: 'underline' }}>Event:</Typography>
      <TextField 
        label='Title'
        size='small'
        margin='normal'
        autoFocus
        value={eventState.title}
        onChange={(ev) => setState({...state, eventState: {...eventState, title: ev.target.value}})}
        onBlur={ev => setState({...state, eventState: {...eventState, title: ev.target.value.trim()}})}
        required
        fullWidth
      />
      <TextField 
        multiline
        minRows={5}
        label='Description'
        size='small'
        margin='normal'
        value={eventState.description}
        onChange={(ev) => setState({...state, eventState: {...eventState, description: ev.target.value}})}
        onBlur={ev => setState({...state, eventState: {...eventState, description: ev.target.value.trim()}})}
        placeholder='details of the event'
        fullWidth
        required
      />
      <FormControl required size='small' margin='normal' fullWidth>
        <InputLabel id='category'>Category</InputLabel>
        <Select
          labelId='category'
          label='Category'
          size='small'
          value={eventState.category}
          onChange={(ev) => setState({...state, eventState: {...eventState, category: ev.target.value}})}
        >
          { categories.map(category => (
            <MenuItem key={category._id} value={category.value}>{ category.label }</MenuItem>
          ))}
        </Select>
      </FormControl>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={afLocale}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={8}>
            <DateRangePicker
              startText='From'
              endText='To'
              inputFormat='dd/MM/yyyy'
              disablePast
              value={eventState?.date || [null, null]}
              onChange={() => {}}
              onAccept={(ev) => setState({...state, eventState: { ...eventState, date: ev }})}
              renderInput={(startProps, endProps) => (
                <>
                  <TextField size='small' margin='normal' {...startProps} />
                  <Box sx={{ mx: 2 }}></Box>
                  <TextField size='small' {...endProps} />
                </>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TimePicker
              label='Time'
              value={eventState?.time}
              onChange={ev => setState({...state, eventState: { ...eventState, time: ev}})}
              renderInput={(params) => (
                <TextField size='small' margin='normal' {...params} fullWidth />
              )}
            />
          </Grid>
        </Grid>
      </LocalizationProvider>
      <Button variant='outlined' component='label' endIcon={<CloudUploadIcon />} sx={{ my: 2}}>
        Event Image
        <Box sx={{ display: 'none' }}>
          <FileBase64
            accept='image/*'
            type='file'
            multiple={false}
            onDone={({ base64, ...rest}) => {
              if(!rest.type.match(/image\/(jpg|jpeg|png)/) || rest.file.size > 5000000) {
                dispatch(notify({ 
                  message: 'Please use the right file type(image) and size(not greater than 5MB)', 
                  _status: 'error'
                }))
                window.scroll({ top: 0, behaviour: 'smooth'})
                base64=''
              }
              setState({...state, eventState: { ...eventState, imageBase64: base64 }})
              base64=''
            }}
            hidden
          />
        </Box>
      </Button> 
    </>
  );
};

export default EventForm
