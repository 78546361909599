import { AbilityBuilder, Ability } from '@casl/ability'

const defineAbilityFor = (user) => {
  const { can, cannot, build } = new AbilityBuilder(Ability)

  if(user?.role === 'Admin') {
    can('manage', 'all')
    cannot('delete', 'User', { id: user.id })
  } else if(user) {
    can(['read', 'update'], 'User', { id: user.id})
    can(['update', 'delete'], 'Event', { userId: user.id})
    can('create', 'Event', { eventCount: { $lt: user.quota}})
  }
  return build()
}
export default defineAbilityFor
