import { useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Link from '@mui/material/Link'
import FavoriteIcon from '@mui/icons-material/Favorite'

const About = (props) => {
  const [ like, setLike ] = useState({ liked: 0 })

  return (
    <Box
      sx={{
        display: { xs: 'flex', md: 'grid' },
        flexDirection: 'column',
        justifyContent: 'center',
        gridTemplateColumns: 'repeat(5, 1fr)' ,
        gridTemplateRows: '70vh 5vh'
      }}
    >
      <Box sx={{
        bgcolor: '#05C3DD',
          display: 'block',
          height: '100%',
          gridColumn: '1 / 3',
          gridRow: 1,
       }}>
      </Box>
      <Box sx={{ gridColumn: ' 2/ -1',
        gridRow: 1,
        alignSelf: 'center'
        }}>
        <Typography variant='h6' sx={{ my: 2}}>About</Typography>
        <Typography sx={{ textAlign: 'justify', mb: '1rem' }}>
          I just want to make this app a one stop to get any event taking place around you,
          If you have any event you have organized or know some events that somebody is organizing
          let people know by posting here, spread the word to others to post their events.
        </Typography>
        <Typography  sx={{ textAlign: 'justify', mb: '1rem'}}>
          It took me sometime to make this  app a reality, during this journey I have learned a lot,
          and I hope it could help many people who are in need of some events to have fun in their spare time,
          learn some thing, and get to know people during the events.
        </Typography>
        <Typography>
          If you like the idea, Please drop me a few lines.
        </Typography>
        <Link component={RouterLink} to='/contact'>contact</Link>

        <Box sx={{ my: '20px', height: '20px' }}>
        { like.liked ? <FavoriteIcon fontSize='large' color='error' /> : null}
        </Box>

      </Box>

      <Box sx={{
        gridRow: 2,
        gridColumn: '5 / 6',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '60px',
          width: '60px',
          borderRadius: '50%',
          alignSelf: { xs: 'flex-end', md: 'end' },
          justifySelf: 'end',
          '&:hover': {
            bgcolor: 'lightgreen',
            color: 'white'
          }
        }}
      >
        <Button
          onClick={() => {
            setLike({ liked: !like.liked })
          }}
          sx={{
            borderRadius: 'inherit',
            border: '1px solid #05c3dd' ,
            height: 'inherit',
            width: 'inherit',
            backgroundColor: like.liked ? 'lightgreen' : 'white'
          }}>Like</Button>
      </Box>
    </Box>
  );
};

export default About
