import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import errorMessage from '../../utils/errorMessage.js'

const initialState = {
  notification: null
}

export const notify = createAsyncThunk(
  'notification/notify',
  async (message, thunkAPI) => {
    try {
      setTimeout(() => {
        thunkAPI.dispatch(resetNotification())
      }, 5000) 
      window.scroll({ top: 0, behavior: 'smooth'})
      return message
    } catch (error) {
      const msg = errorMessage(error)
      return thunkAPI.rejectWithValue(msg)
    }
  }
)

export const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    resetNotification: (state) => {
      state.notification = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(notify.pending, (state) => {
        state.notification = null 
      })
      .addCase(notify.fulfilled, (state, action) => {
        state.notification = action.payload
      })
      .addCase(notify.rejected, (state, action) => {
        state.notification = null
      })
  }
})

export const { resetNotification } = notificationSlice.actions
export default notificationSlice.reducer
