import { Outlet } from 'react-router-dom'

const Users = (props) => {

  return (
    <>
      <Outlet />
    </>
  );
};

export default Users
