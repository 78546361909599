import { useState } from 'react'
import { useNavigate, Link as RouterLink } from 'react-router-dom'

import { useDispatch, useSelector } from 'react-redux'
import { signout } from '../../redux/reducers/userSlice.js'
import { isUserAccessAllowed } from '../../utils/authorize.js'

import IconButton from '@mui/material/IconButton'
import MenuOpenIcon from '@mui/icons-material/MenuOpen'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import LogoutIcon from '@mui/icons-material/Logout'
import LoginIcon from '@mui/icons-material/Login'
import InfoIcon from '@mui/icons-material/Info'
import ContactPageIcon from '@mui/icons-material/ContactPage'
import AccountBoxIcon from '@mui/icons-material/AccountBox'
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount'
import AddIcon from '@mui/icons-material/AddCircle'
import EventIcon from '@mui/icons-material/Event'

import Drawer from '@mui/material/Drawer'
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Link from '@mui/material/Link'

import Divider from '@mui/material/Divider'

const NavbarMenu = (props) => {
  const [ openMenu, setOpenMenu ] = useState(false)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { currentUser } = useSelector(state => state.user)

  const handleLogout = () => {
    dispatch(signout())
    navigate('/')
  }

  const menuList = () => (
    <Box
      sx={{ width: 250, height: 1}}
      role='presentation'
      onClick={() => setOpenMenu(!openMenu)}
    >
      <List>
        <ListItem button>
          <ListItemIcon><ChevronRightIcon fontSize='large' /></ListItemIcon>
          <ListItemText primary='' />
        </ListItem>
        <Divider variant='middle' />
        
        { 
          !currentUser &&
          <Link 
            component={ RouterLink } 
            to='/users/signin' 
            underline='none'
            color='inherit'
          >
            <ListItem button>
              <ListItemIcon><LoginIcon /></ListItemIcon>
              <ListItemText primary='Signin' />
            </ListItem>
          </Link>
        }
        { 
          currentUser && 
          <Link 
            component={ RouterLink } 
            to={`/users/${currentUser.id}/events`} 
            underline='none'
            color='inherit'
          >
            <ListItem button>
              <ListItemIcon><EventIcon /></ListItemIcon>
              <ListItemText primary='My Events' />
            </ListItem>
          </Link>
        }
        {
          currentUser &&
          <Link 
            component={ RouterLink } 
            to={'/events/new'} 
            underline='none' 
            color='inherit'
          >
            <ListItem button>
              <ListItemIcon><AddIcon /></ListItemIcon>
              <ListItemText primary='New Event' />
            </ListItem>
          </Link>
        }
        {
          currentUser &&
          <Link 
            component={ RouterLink } 
            to={`/users/${currentUser.id}`} 
            underline='none'
            color='inherit'
          >
            <ListItem button>
              <ListItemIcon><AccountBoxIcon /></ListItemIcon>
              <ListItemText primary='Profile' />
            </ListItem>
          </Link>
        }
        { 
          currentUser && 
            isUserAccessAllowed(currentUser, 'admin') &&
          <Link 
            component={ RouterLink } 
            to={`/users`}
            underline='none'
            color='inherit'
          >
            <ListItem button>
              <ListItemIcon><SupervisorAccountIcon /></ListItemIcon>
              <ListItemText primary='Admin' />
            </ListItem>
          </Link>
        }
        <Divider variant='middle' />

        <Link
          component={ RouterLink }
          to='/contact'
          underline='none'
          color='inherit'
        >
          <ListItem button>
            <ListItemIcon><ContactPageIcon /></ListItemIcon>
            <ListItemText primary='Contact' />
          </ListItem>
        </Link>
        <Link 
          component={ RouterLink } 
          to='/about'
          underline='none'
          color='inherit'
        >
          <ListItem button>
            <ListItemIcon><InfoIcon /></ListItemIcon>
            <ListItemText primary='About' />
          </ListItem>
        </Link>
        { 
          currentUser && 
          <ListItem button onClick={handleLogout}>
            <ListItemIcon><LogoutIcon /></ListItemIcon>
            <ListItemText primary='Signout' />
          </ListItem>
        }
      </List>
    </Box>
  )

  return (
    <>
      <IconButton color='inherit' size='large' onClick={() => setOpenMenu(true)}>
        <MenuOpenIcon fontSize='large' />
      </IconButton>
      <Drawer
        anchor='right'
        open={openMenu}
        onClose={() => setOpenMenu(!openMenu)} 
      >
        { menuList() }
      </Drawer>
    </>
  );
};

export default NavbarMenu
