import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

// this component is rendered when a user signup for the first time, to inform them to chec their email for verification
// we rendered(use) this in the 'signin page'
const EmailConfirmationMessage = (props) => {
  return (
    <Stack alignItems='center' sx={{ bgcolor: '#f4f4f4', p: '10px' }}>
      <Typography sx={{ textAlign: 'center', pb: 1}}>
        Before trying to login please check your email, we have sent you a confirmation link.
      </Typography> 
      <Typography sx={{ textAlign: 'center'}}>
        sorry for the inconvenience, we have to make sure this is indeed a valid email address.
      </Typography> 
    </Stack>
  );
};

export default EmailConfirmationMessage
