import { useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, Navigate } from 'react-router-dom'
import { notify } from '../../../redux/reducers/notificationSlice.js'
import Progress from '../../../components/Progress/'
import setAuthentication from '../../../utils/setAuthentication.js'
import { updateEvent } from '../../../redux/reducers/eventSlice.js'
import ReCaptcha from '../../../components/ReCaptcha/'

import EventForm from '../../../components/Forms/EventForm.js'
import VenueForm from '../../../components/Forms/VenueForm.js'
import OrganizerForm from '../../../components/Forms/OrganizerForm.js'

import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'

import Button from '@mui/material/Button'
import LoadingButton from '@mui/lab/LoadingButton'

const steps = [ 'Event', 'Venue', 'Organizer' ]

const EditEvent = (props) => {
  const [ activeStep, setActiveStep ] = useState(0)
  const [ submit, setSubmit ] = useState(false)
  const [ updated, setUpdated ] = useState(false)
  const { loading: eventLoading, currentEvent }  = useSelector(state => state._event)
  const { loading: userLoading, currentUser } = useSelector(state => state.user)
  const formRef = useRef()
  const recaptchaRef = useRef()
  const { id } = useParams()

  const [ state, setState ] = useState({
    eventState: currentEvent?._event,
    venueState: currentEvent?.venue,
    organizerState: currentEvent?.organizer
  })
  const dispatch = useDispatch()

  const handleBack = () => {
    setActiveStep(prev => prev - 1)
  }
  const handleNext = () => {
    if(!formRef.current.checkValidity()) {
      dispatch(notify( { message: 'Please fill all the required fields', _status: 'error' }))
      window.scroll({ top: 0, behavior: 'smooth' })
      return 
    }
    setActiveStep(prev => prev + 1)
  }
  const handleUpdate = async () => {
    setSubmit(true)
    const recaptchaToken = await recaptchaRef.current.executeAsync()
    recaptchaRef.current.reset()

    const config = setAuthentication(currentUser)
    const eventData = {
      _event: state.eventState,
      venue: state.venueState,
      organizer: state.organizerState,
      recaptchaToken
    }
    const result = dispatch(updateEvent({id, eventData, config}))
    result.then(() => {
      setUpdated(true)
    })
  }

  const getStepContent = (step) => {
    switch(step) {
      case 0:
        return <EventForm state={state} setState={setState} />
      case 1:
        return <VenueForm state={state} setState={setState} />
      case 2:
        return <OrganizerForm state={state} setState={setState} />
      default:
        throw new Error('Unknown step')
    }
  }

  if(userLoading || eventLoading || !currentEvent) {
    <Progress />
  }
  return (
    <>
      { (!currentUser || !currentEvent) && <Navigate to='/users/signin' /> }
      { updated && <Navigate to={`/users/${ currentUser.id}/events`} /> }
      <Container component='form' maxWidth='md' ref={formRef}>
        <Typography align='center' sx={{mb: 2}}>Edit Event</Typography>   
        <Box>
          <Stepper activeStep={activeStep} alternativeLabel>
            { steps.map(label => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
            ))}
          </Stepper>
        </Box>

        { activeStep === steps.length ? (
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 2}}>
            <Typography sx={{ mb: 2}}>Please click Update to complete</Typography>
            <LoadingButton 
              loading={submit} 
              variant='contained' 
              loadingIndicator='Updating...' 
              onClick={handleUpdate}>Update</LoadingButton>
          </Box>
        ):(
          <>
            { getStepContent(activeStep) }
            <Box sx={{ display: 'flex', justifyContent: 'flex-end'}}>
              <Button disabled={activeStep === 0} onClick={handleBack}>Back</Button>
              <Button onClick={handleNext} variant='contained'>{ activeStep === steps.length - 1 ? 'Done' : 'Next'}</Button>
            </Box>
          </>
        )
        }
      </Container>
      <ReCaptcha recaptchaRef={recaptchaRef} />
    </>
  );
};

export default EditEvent
