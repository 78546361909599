import { Link as RouterLink } from 'react-router-dom'
import { useSelector } from 'react-redux'

import NavbarMenu from './NavbarMenu.js'

import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Link from '@mui/material/Link'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'

const Navbar = (props) => {
  const { currentUser } = useSelector(state => state.user)

  return (
    <AppBar position='static' sx={{ mb: 1}}>
      <Toolbar>
        <Typography variant='h5'>
          <Link 
            component={RouterLink} 
            to='/' 
            color='inherit'
            underline='none'
            sx={{ pl: 1, pr: 2 }}
          >
            Boo
          </Link>
        </Typography>
        <Box sx={{ flexGrow: 1}}></Box>
        <Typography>
          <Link
            component={RouterLink}
            to='/events'
            color='inherit'
            underline='hover'
            sx={{ mr: 2}}
          >
            Events
          </Link>
        </Typography>
        { 
          !currentUser &&
          <Typography>
            <Link
              component={RouterLink}
              to='/users/signin'
              color='inherit'
              underline='hover'
              sx={{ mr: 1}}
            >
              Login
            </Link>
          </Typography>
        }
        {
          currentUser && 
            <Typography>
              <Link
                component={RouterLink} 
                to={`/users/${currentUser.id}`}
                underline='none'
                color='inherit'
                sx={{ mr: 1, display: 'flex', alignItems: 'center'}}
              >
                <AccountCircleIcon />
              </Link>
            </Typography>
        }
        <NavbarMenu />
      </Toolbar>
    </AppBar>
  );
};

export default Navbar
