import Box from '@mui/material/Box'
import Alert from '@mui/material/Alert'

const Notification = ({ notification }) => {
  const { message, _status } = notification
  return (
    <Box sx={{ my: 1}}>
      <Alert sx={{ display: 'flex', justifyContent: 'center'}} severity={_status}>{ message }</Alert> 
    </Box>
  );
};

export default Notification
