import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import CopyrightIcon from '@mui/icons-material/Copyright'

const Footer = (props) => {

  return (
    <Box sx={{ py: 2, mt: 2 }}>
      <Divider  variant='middle' />
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', pt: 2 }}>
        <Typography sx={{ pr: '3px' }}>boo.et</Typography> 
        <CopyrightIcon sx={{ fontSize: '1rem', color: '#757575' }}/> 
        <Typography sx={{ pl: '3px' }}>{ new Date().getFullYear()}</Typography> 
      </Box>
    </Box>
  );
};

export default Footer
