import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

const ShowDateTime = ({ date, time }) => {

  const t = new Date(time).toTimeString().split(':')
  if(!date || !Array.isArray(date)) return null
  return (
    <>
      {
        (date[0] === date[1])
          ?(
            <Box sx={{ display: 'grid', gridTemplateColumns: '1fr', columnGap: 2 }}>
              <Typography  variant='caption'>{ date[0] && new Date(date[0]).toDateString()}</Typography>  
              <Typography  variant='caption' sx={{ textAlign: 'end'}}> { time && `@ ${t[0]}:${t[1]}`}</Typography>  
            </Box>
          )
          :(
            <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 3fr', columnGap: 2 }}>
              <Typography variant='caption'>From:</Typography>  
              <Typography  variant='caption'>{ date[0] && new Date(date[0]).toDateString()}</Typography>  
              <Typography  variant='caption'>To:</Typography>  
              <Typography  variant='caption'>{ date[1] && new Date(date[1]).toDateString()}</Typography>  
            </Box>
          )
      }
    </>
  );
};

export default ShowDateTime
