import { useEffect, useState } from 'react'
import { useParams, Link as RouterLink, Navigate, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getEvent, deleteEvent } from '../../../redux/reducers/eventSlice.js'
import Progress from '../../../components/Progress/'
import ShowDateTime from '../../../components/ShowDateTime/'
import { isEventAccessAllowed } from '../../../utils/authorize.js'
import ShowDialog from '../../../components/ShowDialog/'
import setAuthentication from '../../../utils/setAuthentication.js'
import { RWebShare as WebShare } from 'react-web-share'

import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Card from '@mui/material/Card'
import CardMedia from '@mui/material/CardMedia'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import Link from '@mui/material/Link'

import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import ShareIcon from '@mui/icons-material/Share'

import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

const Event = (props) => {
  const [ edit, setEdit ] = useState(false)
  const [ showDeleteDialog, setShowDeleteDialog ] = useState(false)

  const { id } = useParams()
  const dispatch = useDispatch()
  const { loading, currentEvent } = useSelector(state => state._event)
  const { currentUser } = useSelector(state => state.user )
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(getEvent(id))
  }, [dispatch, id])

  const handleEditClick = () => {
    setEdit(true)
  }
  const handleDeleteClick = () => {
    setShowDeleteDialog(true)
  }

  const handleDelete = () => {
    const config = setAuthentication(currentUser)
    dispatch(deleteEvent({id, config}))
    setShowDeleteDialog(false)
    navigate(-1)
  }

  if(loading || !currentEvent ) {
    return <Progress />
  }
  return (
    <>
      { edit && <Navigate to={`/events/${currentEvent._id}/edit`} /> }
      <Grid container spacing={{ md: 2}}>

        <Grid item sm={1} md={2}></Grid>
        <Grid item xs={12} sm={10} md={8}>
          <Card>
            <CardMedia
              component='img'
              sx={{ height: { xs: '280px', sm: '300px',  md: '300px'}}}
              image={currentEvent._event.image}
              alt={currentEvent._event.title}
            />
            <CardContent>
              <Typography variant='h5' sx={{ mb: 1}}>{currentEvent._event.title}</Typography>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 1}}>
                { currentEvent && <ShowDateTime date={currentEvent?._event?.date} time={currentEvent._event.time}/> }
              </Box>
              <Typography>{currentEvent._event.description}</Typography>
            </CardContent>
            <CardActions>
              { currentUser && 
                isEventAccessAllowed(currentUser, 'update', currentEvent) &&
                <Tooltip title='edit' arrow>
                  <IconButton onClick={handleEditClick}><EditIcon /></IconButton>
                </Tooltip>
              }
              { currentUser && 
                isEventAccessAllowed(currentUser, 'delete', currentEvent) &&
                <Tooltip title='delete' arrow>
                  <IconButton onClick={handleDeleteClick}><DeleteIcon /></IconButton>
                </Tooltip>
              }
              <WebShare
                data={{
                  title: `${currentEvent._event.title}` ,
                  text: `${currentEvent._event.title}` 
                }}
              >
                <IconButton><ShareIcon /></IconButton>
              </WebShare>
            </CardActions>
          </Card>      
        </Grid>
        <Grid item sm={1} md={2}></Grid>

        <Grid item xs={12} md={6}>
          <Paper elevation={1} sx={{ mt: 2, p: 2}}>
            <Typography variant='h5' sx={{mb: 2 }}>Venue: </Typography>
            <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 3fr', rowGap: 1 }}>
              <Typography>Name: </Typography>
              <Typography>{currentEvent.venue.name}</Typography>
              <Typography>Street: </Typography>
              <Typography>{currentEvent.venue.street}</Typography>
              <Typography>City: </Typography>
              <Typography>{currentEvent.venue.city}</Typography>
              <Typography>State: </Typography>
              <Typography>{currentEvent.venue.state}</Typography>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper sx={{ mt: 2, p: 2}}>
            <Typography variant='h5' sx={{mb: 2 }}>Organizer: </Typography>
            <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 3fr', rowGap: 1 }}>
              <Typography>Name: </Typography>
              <Typography>{currentEvent.organizer.name}</Typography>
              <Typography>Address: </Typography>
              <Typography>{currentEvent.organizer.address}</Typography>
              <Typography>Phone: </Typography>
              <Typography>{currentEvent.organizer.phone}</Typography>
              <Typography>WebSite: </Typography>
              <Typography>{currentEvent.organizer.url}</Typography>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ my: 3}}>
            <Typography sx={{ mb: 1}}>
              <Link 
                component={RouterLink} 
                to={`/events?category=${currentEvent._event.category}`}
                sx={{ bgcolor: '#000', color: 'white', padding: '5px', borderRadius: '3px', textDecoration: 'none'}}
              >
                {currentEvent._event.category}
              </Link>
            </Typography>
            <Typography>
              <Link 
                component={RouterLink} 
                to={`/users/${currentEvent.user.id}/events?active=true`}
              >
                more from user
              </Link>
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <ShowDialog 
        show={showDeleteDialog} 
        setShow={setShowDeleteDialog} 
        title='Delete confirmation'
        action='Delete'
        content='Please confirm delete operation'
        handleAction={handleDelete}
      />
    </>
  );
};

export default Event
