import { useState } from 'react'
import { Navigate, useSearchParams, useNavigate } from 'react-router-dom'
import ShowDateTime from '../ShowDateTime/'

import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import CardActionArea from '@mui/material/CardActionArea'
import CardMedia from '@mui/material/CardMedia'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'

import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'

const Event = ({ _event }) => {
  const [ showDetail, setShowDetail ] = useState(false)
  const [ searchParams, setSearchParams ] = useSearchParams()
  const navigate = useNavigate()

  const { _event: ev } = _event

  const handleShowDetail = () => {
    setShowDetail(true)
  }

  const handleCategoryClick = () => {
    searchParams.set('category', ev.category)
    setSearchParams(searchParams.toString())
    navigate(`/events?${searchParams.toString()}`)
  }

  return (
    <>
      { showDetail && <Navigate to={`/events/${_event._id}`} /> }
      <Grid item xs={12} md={6} lg={4}>
        <Card>
          <CardActionArea onClick={handleShowDetail}>
            <CardMedia
              component='img'
              sx={{ height: { xs: '180px', sm: '250px', md: '200px' }}}
              image={ev.image}
              alt={ev.title}
            />
            <CardContent>
              <Typography variant='h5' sx={{mb: 1}}>{ev.title}</Typography>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 1}}>
                 <ShowDateTime date={ev.date} time={ev.time} />
              </Box>
              <Typography variant='body1' sx={{ textAlign: 'justify' }}>{ev.description.substring(0, 150)}</Typography>
            </CardContent>
          </CardActionArea>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mx: 2, pt: 2 }}>
              <Button 
                variant='contained'
                size='small'
                onClick={handleCategoryClick}
              >
               {ev.category}
              </Button>
              <Button size='small' onClick={handleShowDetail}>Detail</Button>
            </Box>
          <CardActions> 
          </CardActions>
        </Card>
      </Grid>
    </>
  );
};

export default Event
