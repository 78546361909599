import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'

const Progress = (props) => {

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3}}>
      <CircularProgress />
    </Box>
  );
};

export default Progress
