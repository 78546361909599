import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'

const ShowDialog = (props) => {
  const { show, setShow, title, action, content, handleAction } = props

  return (
    <Dialog
      open={show}
      onClose={() => setShow(false)}
    >
      <DialogTitle sx={{ textDecoration: 'underline' }}>{ title }</DialogTitle>
      <DialogContent>
        <DialogContentText>
          { content }
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleAction}>{action}</Button> 
        <Button onClick={() => setShow(false)}>Cancel</Button> 
      </DialogActions>
    </Dialog>
  );
};

export default ShowDialog
