import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { verifyEmail } from '../../../services/user.js'
import Progress from '../../../components/Progress/'
import errorMessage from '../../../utils/errorMessage.js'
import { notify } from '../../../redux/reducers/notificationSlice.js'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

const VerifyEmail = (props) => {
  const [ err, setErr ] = useState(false)
  const [ message, setMessage ] = useState('')
  const [ verify, setVerify ] = useState(false)
  const { verifyToken } = useParams()
  const dispatch = useDispatch()

  useEffect(() => {
    const verification = async () => {
      try {
        const { data } = await verifyEmail(verifyToken)
        setVerify(true)
        setMessage(data.message)
        dispatch(notify({ message: data.message, _status: 'success' }))
      } catch (error) {
        setErr(true)
        const message = errorMessage(error)
        dispatch(notify({ message, _status: 'error' }))
      }
    }
    verification()
  }, [dispatch, verifyToken])

  if(err) {
    return <Typography>Error...</Typography>
  }
  if(!verify) {
    return <Progress />
  }

  return (
    <Box 
      sx={{
        width: { xs: '80%', md: '50%'},
          margin: '0 auto',
          marginTop: 8, 
          textAlign: 'center'
      }}
    >
      <Typography>{ message }</Typography> 
    </Box>
  );
};

export default VerifyEmail
