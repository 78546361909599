import { Outlet } from 'react-router-dom'
const Events = (props) => {

  return (
    <>
      <Outlet />
    </>
  );
};

export default Events
