import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import errorMessage from '../../utils/errorMessage.js'
import { notify } from './notificationSlice.js'
import * as userAPI from '../../services/user.js'

const initialState = {
  loading: false,
  currentUser: null,
  user: {},
  users: []
}

export const cleanUsers = createAsyncThunk(
  'users/cleanUsers',
  async (config, thunkAPI) => {
    try {
      const { data } = await userAPI.cleanUsers(config)  
      thunkAPI.dispatch(notify({message: data.message, _status: 'info' }))
      return data
    } catch (error) {
      const message = errorMessage(error)
      thunkAPI.dispatch(notify({message, _status: 'error' }))
      thunkAPI.rejectWithValue(message)
    } 
  }
)
export const getUsers = createAsyncThunk(
  'user/getUsers',
  async (config, thunkAPI) => {
    try {
      const { data } = await userAPI.fetchUsers(config)
      return data
    } catch (error) {
      const message = errorMessage(error)
      thunkAPI.dispatch(notify({ message, _status: 'error' }))
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const updateUser = createAsyncThunk(
  'user/updateUser',
  async ({id, updateInfo, config }, thunkAPI) => {
    try {
      const { data } = await userAPI.updateUser(id, updateInfo, config) 
      if(data.modifiedCount > 0) {
        const { currentUser } = thunkAPI.getState().user
        const config = {
          headers: { authentication: `Bearer ${currentUser?.token}`}
        }
        thunkAPI.dispatch(notify({ message: 'profile updated', _status: 'success' }))
        thunkAPI.dispatch(getUser({id, config}))
      } else {
        throw new Error('Nothing updated')
      }
    } catch (error) {
      const message = errorMessage(error)
      thunkAPI.dispatch(notify({ message, _status: 'error' }))
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const getUser = createAsyncThunk(
  'user/getUser',
  async ({ id, config }, thunkAPI) => {
    try {
      const { data } = await userAPI.fetchUser(id, config) 
      return data
    } catch (error) {
      const message = errorMessage(error)
      thunkAPI.dispatch(notify({ message, _status: 'error' }))
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const loginFromLocalStorage = createAsyncThunk(
  'user/loginFromLocalStorage',
  async (loggedInUser, thunkAPI) => {
    try {
      await userAPI.verifyUserJWToken({ token: loggedInUser.token })  
      return loggedInUser
    } catch (error) {
      const message = errorMessage(error)
      thunkAPI.dispatch(notify({ message, _status: 'error'}))
      // this could be wrong, if network error happend we dont have to remove currentUser 
      window.localStorage.removeItem('currentUser')
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const signinUser = createAsyncThunk(
  'user/signinUser',
  async (signinData, thunkAPI) => {
    try {
      const { data } = await userAPI.signinUser(signinData) 
      thunkAPI.dispatch(notify({ message: data.message, _status: 'success' }))
      if(signinData.rememberMe) {
        window.localStorage.setItem('currentUser', JSON.stringify(data.user))
      }
      return data.user
    } catch (error) {
      const message = errorMessage(error)
      thunkAPI.dispatch(notify({ message, _status: 'error'}))
      return thunkAPI.rejectWithValue(message)
    }
  }
)

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    signout: (state, action) => {
      state.currentUser = null
      state.user = {}
      state.users = []
      window.localStorage.removeItem('currentUser')
    },
    updateEventCount: (state, action) => {
      state.currentUser = {...state.currentUser, eventCount: state.currentUser.eventCount + action.payload}
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(signinUser.pending, (state) => {
        state.loading = true
      })
      .addCase(signinUser.fulfilled, (state, action) => {
        state.loading = false
        state.currentUser = action.payload
      })
      .addCase(signinUser.rejected, (state) => {
        state.loading = false
        state.currentUser = null
      })
    builder
      .addCase(loginFromLocalStorage.pending, (state) => {
        state.loading = true
      })
      .addCase(loginFromLocalStorage.fulfilled, (state, action) => {
        state.loading = false
        state.currentUser = action.payload
      })
      .addCase(loginFromLocalStorage.rejected, (state) => {
        state.loading = false
        state.currentUser = null
      })
    builder
      .addCase(getUser.pending, (state) => {
        state.loading = true
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.loading = false
        state.user = action.payload
      })
      .addCase(getUser.rejected, (state) => {
        state.loading = false
        state.user = {}
      })
    builder
      .addCase(getUsers.pending, (state) => {
        state.loading = true
      })
      .addCase(getUsers.fulfilled, (state, action) => {
        state.loading = false
        state.users = action.payload
      })
      .addCase(getUsers.rejected, (state) => {
        state.loading = false
        state.users = []
      })
  }
})

export const { signout, updateEventCount } = userSlice.actions;
export default userSlice.reducer
