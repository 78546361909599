import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import Progress from '../Progress/'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'

import LocalizationProvider from '@mui/lab/LocalizationProvider'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import DatePicker  from '@mui/lab/DatePicker'
import TextField  from '@mui/material/TextField'
import afLocale from 'date-fns/locale/af'

const Filter = (props) => {
  const { loading: categoriesLoading,  categories } = useSelector(state => state.category)
  const { loading: regionsLoading, regions } = useSelector(state => state.region)
  const [ searchParams, setSearchParams ] = useSearchParams()
  const [ state, setState ] = useState('') 
  const [ category, setCategory ] = useState('') 
  const [ date, setDate ] = useState(null) 

  const handleStateChange = (ev) => {
    setState(ev.target.value)
    if(ev.target.value === 'All') {
      searchParams.delete('state')
      setSearchParams(searchParams.toString())
    } else {
      searchParams.set('state', ev.target.value)
      setSearchParams(searchParams.toString())
    }
  }

  const handleCategoryChange = (ev) => {
    setCategory(ev.target.value)
    if(ev.target.value === 'all') {
      searchParams.delete('category')
      setSearchParams(searchParams.toString())
    } else {
      searchParams.set('category', ev.target.value)
      setSearchParams(searchParams.toString())
    }
  }

  const handleDateAccepted = (newValue) => {
    if(newValue) {
      setDate(newValue.toDateString())
      searchParams.set('date', newValue.toDateString())
      setSearchParams(searchParams.toString())
    } else {
      setDate(null)
      searchParams.delete('date')
      setSearchParams(searchParams.toString())
    }
  }

  useEffect(() => {
    if(searchParams.has('category')) {
      setCategory(searchParams.get('category'))
    }
    if(searchParams.has('state')) {
      setState(searchParams.get('state'))
    }
    if(searchParams.has('date')) {
      setDate(searchParams.get('date'))
    }
  }, [ searchParams ])

  if( categoriesLoading || regionsLoading ) {
    return <Progress />
  }
  return (
    <Grid container spacing={2} sx={{ mt: 1 }}>
      <Grid item xs={12} md={6} lg={8}><Typography varaint='h5'>Events</Typography></Grid>
      <Grid item xs={12} md={6} lg={4}>
        <Box sx={{ display: 'flex', mb: 2}}>
          <FormControl size='small' fullWidth>
            <InputLabel id='state'>State</InputLabel>
            <Select
              labelId='state'
              label='State'
              value={state}
              onChange={handleStateChange}
              size='small'
            >
              {
                [{_id: 1, state: 'All'}, ...regions].map(region => (
                  <MenuItem key={ region._id} value={region.state}>{region.state}</MenuItem>
                ))
              }
            </Select>
          </FormControl>
          <FormControl size='small' fullWidth  sx={{ ml: 1}}>
            <InputLabel id='category'>Category</InputLabel>
            <Select
              labelId='category'
              label='category'
              value={category}
              onChange={handleCategoryChange}
              size='small'
            >
              {
                [{_id: 1, value: 'all', label: 'All'}, ...categories].map(category => (
                  <MenuItem key={ category._id} value={category.value}>{category.label}</MenuItem>
                ))
              }
            </Select>
          </FormControl>
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={afLocale}>
            <DatePicker 
              label='Date'
              inputFormat='dd/MM/yyyy'
              disablePast
              clearable={true}
              allowSameDateSelection
              value={date}
              onChange={() => {}}
              onAccept={handleDateAccepted}
              renderInput={params => <TextField {...params} size='small' fullWidth   sx={{ ml: 1}}/>}
            />            
          </LocalizationProvider>
        </Box> 
      </Grid>
    </Grid>
  );
};

export default Filter
