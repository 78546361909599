import { useState } from 'react'
import { useSelector } from 'react-redux'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Grid from '@mui/material/Grid'

const VenueForm = ({ state, setState}) => {
  const { venueState } = state
  const { regions } = useSelector(state => state.region)
  const [ region, setRegion ] = useState(regions.find(region => region.state === venueState.state))

  return (
    <>
      <Typography  sx={{ textDecoration: 'underline' }}>Venue:</Typography>
      <TextField 
        label='Name'
        size='small'
        margin='normal'
        autoFocus
        required
        value={venueState?.name}
        onChange={(ev) => setState({...state, venueState: {...venueState, name: ev.target.value }})}
        onBlur={(ev) => setState({...state, venueState: {...venueState, name: ev.target.value.trim()}})}
        fullWidth
      />
      <TextField 
        label='Street'
        size='small'
        margin='normal'
        required
        value={venueState?.street}
        onChange={(ev) => setState({...state, venueState: {...venueState, street: ev.target.value }})}
        onBlur={(ev) => setState({...state, venueState: {...venueState, street: ev.target.value.trim()}})}
        fullWidth
      />
      <Grid container spacing={{ xs: 0, sm: 2}}>
        <Grid item xs={12} sm={6}>
          <FormControl required size='small' margin='normal' fullWidth>
            <InputLabel id='state'>State</InputLabel>
            <Select
              labelId='state'
              label='State'
              value={venueState?.state}
              onChange={ev => {
                setState({ ...state, venueState: {...venueState, state: ev.target.value, city: ''}})
                const reg = regions.find(region => region.state === ev.target.value)
                setRegion(reg)
              }}
            >
              {
                regions?.map((region) => (
                  <MenuItem key={region?._id} value={region.state}>{ region?.state }</MenuItem>
                )) 
              }
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
        <FormControl size='small' margin='normal' required  sx={{mb: 3}} fullWidth>
          <InputLabel id='city'>City</InputLabel>
          <Select
            labelId='city'
            label='City'
            value={venueState?.city}
            onChange={ev => setState({...state, venueState: {...venueState, city: ev.target.value}})}
          >
            {
              region?.cities.map(city => (
                <MenuItem key={city} value={city}>{city}</MenuItem>
              ))
            }
          </Select>
        </FormControl>
        </Grid>
      </Grid>
    </>
  );
};

export default VenueForm
