import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import errorMessage from '../../utils/errorMessage.js'
import { notify } from './notificationSlice.js'
import * as eventAPI from '../../services/event.js'
import { updateEventCount } from './userSlice.js'

const initialState = {
  loading: false,
  pages: 0,
  events: [],
  userEvents: [],
  currentEvent: null
}

export const updateEvent = createAsyncThunk(
  'event/updateEvent',
  async ({id, eventData, config}, thunkAPI) => {
    try {
      const { data } = await eventAPI.updateEvent(id, eventData, config)
      thunkAPI.dispatch(notify({ message: data.message, _status: 'success' }))
//      if(data.result) {
//        return data.result.value
//      }
      return data
    } catch (error) {
      const message = errorMessage(error)
      thunkAPI.dispatch(notify({ message, _status: 'error'}))
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const getEvents = createAsyncThunk(
  'event/getEvents',
  async (params, thunkAPI) => {
    try {
      const { data } = await eventAPI.fetchEvents(params)
      return data
    } catch (error) {
      const message = errorMessage(error)
      thunkAPI.dispatch(notify({ message, _status: 'error'}))
      return thunkAPI.rejectWithValue(message)
    } 
  }
)

export const deleteEvent = createAsyncThunk(
  'event/deleteEvent',
  async ({id, config}, thunkAPI) => {
    try {
      const { data } = await eventAPI.deleteEvent(id, config)
      thunkAPI.dispatch(notify({ message: data.message, _status: 'info'}))
      thunkAPI.dispatch(updateEventCount(-1))
      const { user }= thunkAPI.getState()
      window.localStorage.setItem('currentUser', JSON.stringify(user.currentUser))
      return data.result
    } catch (error) {
      const message = errorMessage(error)
      thunkAPI.dispatch(notify({ message, _status: 'error'}))
      return thunkAPI.rejectWithValue(message)
    }
  }
)
export const getUserEvents = createAsyncThunk(
  'event/getUserEvents',
  async ({ id, params }, thunkAPI) => {
    try {
      const { data } = await eventAPI.fetchUserEvents(id, params)
      return data
    } catch (error) {
      const message = errorMessage(error)
      thunkAPI.dispatch(notify({ message, _status: 'error'}))
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const postEvent = createAsyncThunk(
  'event/postEvent',
  async ({eventData, config}, thunkAPI) => {
    try {
      const { data } = await eventAPI.createEvent(eventData, config)
      thunkAPI.dispatch(notify({ message: data.message, _status: 'success' }))
      thunkAPI.dispatch(updateEventCount(1))
      const { user }= thunkAPI.getState()
      window.localStorage.setItem('currentUser', JSON.stringify(user.currentUser))
      return data.result
    } catch (error) {
      const message = errorMessage(error)
      thunkAPI.dispatch(notify({ message, _status: 'error'}))
      return thunkAPI.rejectWithValue(message)
    }
  }
)
export const getEvent = createAsyncThunk(
  'event/getEvent',
  async (id, thunkAPI) => {
    try {
      const { data } = await eventAPI.fetchEvent(id)
      return data
    } catch (error) {
      const message = errorMessage(error)
      thunkAPI.dispatch(notify({ message, _status: 'error'}))
      return thunkAPI.rejectWithValue(message)
    }
  }
)

const eventSlice = createSlice({
  name: 'event',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getEvents.pending, (state) => {
        state.loading = true 
      })
      .addCase(getEvents.fulfilled, (state, action) => {
        state.loading = false
        state.events = action.payload.events
        state.pages = action.payload.pages
      })
      .addCase(getEvents.rejected, (state) => {
        state.loading = false
        state.events = []
        state.pages = 0
      })
    builder
      .addCase(getEvent.pending, (state) => {
        state.loading = true 
      })
      .addCase(getEvent.fulfilled, (state, action) => {
        state.currentEvent = action.payload
        state.loading = false
      })
      .addCase(getEvent.rejected, (state) => {
        state.loading = false
      })
    builder
      .addCase(postEvent.pending, (state) => {
        state.loading = true
      })
      .addCase(postEvent.fulfilled, (state, action) => {
        state.loading = false
        state.events.push(action.payload)
        state.userEvents.push(action.payload)
      })
      .addCase(postEvent.rejected, (state) => {
        state.loading = false
      })
    builder
      .addCase(getUserEvents.pending, (state) => {
        state.loading = true
      })
      .addCase(getUserEvents.fulfilled, (state, action) => {
        state.loading = false
        state.userEvents = action.payload
      })
      .addCase(getUserEvents.rejected, (state) => {
        state.loading = false
      })
    builder
      .addCase(updateEvent.pending, (state) => {
        state.loading = true
      })
      .addCase(updateEvent.fulfilled, (state, action) => {
        state.loading = false
      //  const userEvs = [...state.userEvents ]
      //  const idx = userEvs.findIndex(ev => ev._id === action.payload._id)
      //  userEvs.splice(idx, 1, action.payload)
      //  state.userEvents = userEvs
      })
      .addCase(updateEvent.rejected, (state) => {
        state.loading = false
      })
  }
})

export default eventSlice.reducer
