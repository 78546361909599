import { Routes, Route } from 'react-router-dom'

import Users from './pages/users/'
import Signup from './pages/users/Signup/'
import Signin from './pages/users/Signin/'
import ForgotPassword from './pages/users/ForgotPassword/'
import ResetPassword from './pages/users/ResetPassword/'
import VerifyEmail from './pages/users/VerifyEmail/'

import User from './pages/users/User/'
import Profile from './pages/users/User/Profile.js'
import EditProfile from './pages/users/User/EditProfile.js'
import UsersList from './pages/users/UsersList'
import UserEvents from './pages/users/User/UserEvents.js'

import Pages from './pages/'
import Terms from './pages/Terms/'
import Contact from './pages/Contact/'
import About from './pages/About/'
import NotFound from './pages/NotFound/'
import Events from './pages/events/'
import EventList from './pages/events/Events.js'
import NewEvent from './pages/events/NewEvent/'
import Event from './pages/events/Event/'
import EditEvent from './pages/events/EditEvent/'

const Router = () => {
  return (
    <Routes>
      <Route path='/' element={<Pages />}>
        <Route index element={<EventList />} />
        <Route path='terms' element={<Terms />} />
        <Route path='contact' element={<Contact />} />
        <Route path='about' element={<About />} />
        <Route path='users' element={<Users />}>
          <Route index element={<UsersList />} />
          <Route path='signup' element={<Signup />} />
          <Route path='signin' element={<Signin />} />
          <Route path='forgotpassword' element={<ForgotPassword />} />
          <Route path='resetpassword/:resetToken' element={<ResetPassword />} />
          <Route path='verifyemail/:verifyToken' element={<VerifyEmail />} />
          <Route path=':id' element={<User />}>
            <Route index element={<Profile />} /> 
            <Route path='edit' element={<EditProfile />} /> 
            <Route path='events' element={<UserEvents />} /> 
          </Route>
        </Route>
        <Route path='events' element={<Events />}>
          <Route index element={<EventList />} />
          <Route path='new' element={<NewEvent />} />
          <Route path=':id' element={<Event />} />
          <Route path=':id/edit' element={<EditEvent />} />
        </Route>
      </Route>
      <Route path='*' element={<NotFound />} />
    </Routes>
  )
}

export default Router
