import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'

import EmailIcon from '@mui/icons-material/Email'

const Contact = (props) => {

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
      <Typography variant='h6' sx={{ mb: 2 }}>Contact</Typography> 
      <Typography variant='body2'>
        {`If you have any suggestions or comments don't hesitate to contact me`}
      </Typography>
        @ {<EmailIcon fontSize='small' />}
      <Typography sx={{ mt: 1 }}>
        <Link 
          href="mailto: boo.events.org@gmail.com?subject=suggestions and/or comments"
        >
          boo.events.org@gmail.com
        </Link>
      </Typography>
    </Box>
  );
};

export default Contact
