import ReCAPTCHA from 'react-google-recaptcha'

const ReCaptcha = (props) => {

  const { recaptchaRef } = props
  return (
    <>
      <ReCAPTCHA
        sitekey={process.env.REACT_APP_RECAPTCHA_SITEKEY}
        size='invisible'
        ref={recaptchaRef}
      />
    </>
  );
};

export default ReCaptcha
