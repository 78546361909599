import { useEffect } from 'react'
import Router from './router.js'
import { useSelector, useDispatch } from 'react-redux'
import { loginFromLocalStorage } from './redux/reducers/userSlice.js'
import { getCategories } from './redux/reducers/categorySlice.js'
import { getRegions } from './redux/reducers/regionSlice.js'
import Footer from './components/Footer/'

import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'

import Navbar from './components/Navbar/'
import Notification from './components/Notification/'

import CssBaseline from '@mui/material/CssBaseline'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'

const App = () => {
  const { notification } = useSelector(state => state.notification)
  const dispatch = useDispatch()

  useEffect(() => {
    const currentUserJSON = window.localStorage.getItem('currentUser')

    if(currentUserJSON) {
      const loggedInUser = JSON.parse(currentUserJSON)
      dispatch(loginFromLocalStorage(loggedInUser))
    }

  }, [dispatch])

  useEffect(() => {
    dispatch(getCategories())
  }, [dispatch])

  useEffect(() => {
    dispatch(getRegions())
  }, [dispatch])

  return (
    <>
      <CssBaseline />
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <Navbar />
        <Container maxWidth='lg' sx={{ flex: 1 }}>
          { notification && <Notification notification={notification} /> }

          <Router />
        </Container>
        <Footer />
      </Box>
    </>
  );
}

export default App;
