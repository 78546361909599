import { useState } from 'react'
import  Snackbar from '@mui/material/Snackbar'

const SnackbarMessage = ({ notLoggedIn, limitReached, message }) => {
  const [ open, setOpen ] = useState(notLoggedIn || limitReached)

  const handleClose = (ev, reason) => {
    if(reason === 'clickaway'){
      return
    }
    setOpen(false)
  }

  return (
    <>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={message}
      />
    </>
  );
};

export default SnackbarMessage
